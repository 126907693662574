<template>
    <div style="width: 70%;">


        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">商品条码：</p>
          <div class="addAdmin_select">
            <span>示例：</span>
            <pre>
                31202307001xx
                31202309018xx
                31202309212xx
            </pre>
            <el-input type="textarea" rows="8" v-model="dryData.skus"></el-input>
          </div>
        </div>

        <!--  -->
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">禁配地区：</p>
          <div class="addAdmin_select">
            <el-select
                v-model="dryData.value"
                filterable
                placeholder="请选择"
                multiple
                style="width: 80%"
              >
                <el-option
                  v-for="item in dryData.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">禁配备注：</p>
          <div class="addAdmin_select">
            <el-input type="textarea" :rows="4" v-model="dryData.ban_remark"></el-input>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">快速选择：</p>
          <div class="addAdmin_select">
            <el-checkbox-group v-model="checkboxGroupSel" size="small">
                <el-checkbox
                  label="欧盟"
                  border
                  @change="(val) => changeCountry(val, '欧盟')"
                ></el-checkbox>
                <el-checkbox
                  label="伊斯兰教"
                  border
                  @change="(val) => changeCountry(val, '伊斯兰教')"
                ></el-checkbox>
                <el-checkbox
                  label="海湾"
                  border
                  @change="(val) => changeCountry(val, '海湾')"
                ></el-checkbox>
                <el-checkbox
                  label="东盟"
                  border
                  @change="(val) => changeCountry(val, '东盟')"
                ></el-checkbox>
                <el-checkbox
                  label="全部"
                  border
                  @change="(val) => changeCountry(val, '全部')"
                ></el-checkbox>
              </el-checkbox-group>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet"></p>
            <div class="addAdmin_select">
                <el-button
                type="primary"
                @click="configmSubmit"
                >修 改</el-button>
            </div>
        </div>



    </div>
  </template>

  <script>


  export default {
    name: "editBanArea",
    data() {
      return {
        //禁配数据
        dryData: {
            value: [],
            options: [],
            ban_remark:"",
            skus:"",
        },
        checkboxGroupSel: [],
        countryArr: {
            "欧盟": [
            "DE",
            "FR",
            "IT",
            "ES",
            "PT",
            "NL",
            "BE",
            "LU",
            "IE",
            "AT",
            "HU",
            "PL",
            "CZ",
            "SK",
            "SI",
            "HR",
            "DK",
            "SE",
            "FI",
            "CY",
            "MT",
            "GR",
            "RO",
            "BG",
            "EE",
            "LV",
            "LT",
            "GB",
            "REU",
            ],
            "伊斯兰教": [
            "MY",
            "BN",
            "ID",
            "BD",
            "MV",
            "PK",
            "AFG",
            "KZ",
            "UZ",
            "TJ",
            "KGZ",
            "TKM",
            "AZ",
            "IR",
            "IQ",
            "KW",
            "SY",
            "TR",
            "JO",
            "BL",
            "SA",
            "BH",
            "QA",
            "ARE",
            "OM",
            "YE",
            "AF",
            "LBY",
            "TUN",
            "DZ",
            "MAR",
            "SDN",
            "MRT",
            "SO",
            "DJI",
            "COM",
            "SEN",
            "GMB",
            "GIN",
            "SLE",
            "MLI",
            "NER",
            "NGA",
            "TCD",
            "ALB",
            "BIH",
            "XKX",
            ],
            "海湾": ["SA", "KW", "BH", "QA", "ARE", "OM", "YE"],
            "东盟": ["VN", "LA", "KH", "TH", "MM", "PH", "MY", "SG", "BN", "ID"],
        },
      };
    },
    created() {
        this.getCountryList()
    },
    methods: {
        //获取国家
        getCountryList() {
            this.dryData.options = [];
            this.$axios.get(`/a1/country`).then((res) => {
                let options = [];
                let resData = res.data.data;
                //this.$message.success(resData[1].code,)
                for (let i = 0; i < resData.length; i++) {
                let newData = {
                    value: resData[i].code,
                    label: resData[i].name,
                };
                    options.push(newData);
                }
                this.dryData.options = options;
            });
        },
         /* 确认提交修改 */
         configmSubmit(){
            if(confirm('你确定修改预售配置?')){
                this.handleSubmit()
            }
        },

        /* 提交表单 */
        handleSubmit(){
            // 整理配置数据
            let arr=[];
            this.dryData.skus.split("\n").forEach(item => {
                if (item) {
                    arr.push(item.trim());
                }
            });
            let banStr = this.dryData.value.join(",");
            let postData = {
                ban_area: banStr + "",
                ban_remark:this.dryData.ban_remark,
                skus:arr
            };

            //修改参数
            this.$axios.put(`/a1/presell/presellBanArea`, postData)
            .then((res) => {
                if(res.error==1){
                    this.$message.error(res.message)
                }else{
                    this.$message.success("修改成功");
                    this.getData();
                }
            })
            .catch((resErr) => {
                this.$message.error("修改失败："+resErr.response.data.message);
            });
        },

        //=========== 快速选中国家效果 begin
        changeCountry(val, name) {
            if (name === "全部") {
                if (val) {
                //选中状态
                for (let key in this.dryData.options) {
                    let code = this.dryData.options[key].value;
                    //不存在 则 增加
                    if (!this.dryData.value.includes(code)) {
                        this.dryData.value.push(code);
                    }
                }
                } else {
                    this.dryData.value = [];
                }
            }
            //其他特殊地区
            this.checkboxGroupSelFun(val, name);
            },
            //获取国家在选中keyId，以方便做删除
            getKeyId(str, forArr) {
            let keyId = 0;
            if (forArr.length > 0) {
                for (let key in forArr) {
                    if (forArr[key] === str) {
                        keyId = key;
                    }
                }
            }
            return keyId;
            },
            //快速选择
            checkboxGroupSelFun(ok, name) {
            if (ok) {
                //选中状态
                for (let key in this.countryArr[name]) {
                    let code = this.countryArr[name][key];
                    //不存在 则 增加
                    if (!this.dryData.value.includes(code)) {
                        this.dryData.value.push(code);
                    }
                }
            } else {
                //非选中状态
                for (let key in this.countryArr[name]) {
                    let code = this.countryArr[name][key];
                    //存在 则 删除
                    if (this.dryData.value.includes(code)) {
                        let delKey = this.getKeyId(code, this.dryData.value);
                        this.dryData.value.splice(delKey, 1);
                    }
                }
            }
        },
    //=========== 快速选中国家效果 end


    },
  };
  </script>

  <style lang="scss" scoped>
  .addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;

  &_CharacterSet {
    font-size: 15px;
    width: 200px;
    text-align: right;
  }

  &_select {
    flex: 1;
    justify-content: flex-start;
  }
  &_swith {
    text-align: center;
  }
}

::v-deep{
    .addAdmin_select > .el-input{
        width: 220px;
        padding-right: 0;
    }
}

  </style>
