<template>
    <div class="log-content">
  
      <div style="padding: 10px;border-bottom:  1px solid rgba(153,153,153,0.56)">
        <el-input size="mini" v-model="quyeryData.search" class="pageBody_title_query_input"  style="width: 230px" placeholder=" sku | 海鼎编码"></el-input>
        <el-button size="mini" style="margin-left:10px" type="primary" @click="handleQuery">查 询</el-button>
      </div>
  
      <el-table :data="logData" width="100%" height="660">
        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="item_name" label="商品名称" width="280">
            <template slot-scope="scope">
                <div>
                中文:{{ scope.row.PresellGoods.item_name }}
                </div>
                <div>
                英文:{{ scope.row.PresellGoods.item_en }}
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="hd_sku" label="编码" align="center" width="150">
            <template slot-scope="{row}">
                <p>预售:{{ row.item_no }}</p>
                <p>海鼎:{{ row.hd_sku ? row.hd_sku : "未定义" }}</p>
            </template>
        </el-table-column>
        <el-table-column prop="user_name" label="操作人" width="80" align="center"></el-table-column>
        <el-table-column prop="content" label="操作内容" align="center">
          <template slot-scope="scope">
              <pre style="word-break: break-word;white-space: pre-wrap;">{{ scope.row.content }}</pre>
            </template>
        </el-table-column>
        <el-table-column prop="created_time" label="操作时间" align="center">
          <template slot-scope="scope">
                            <span>{{ new Date(scope.row.created_time*1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}</span>
            </template>
        </el-table-column>
      </el-table>
  
      <el-pagination
        style="padding-top: 15px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pagesData.page"
        :page-sizes="[20, 50, 80, 100, 200]"
        :page-size="pagesData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagesData.total">
      </el-pagination>
  
    </div>
  </template>
  
  <script>
  
  import presellApi from "@/api/admin/presell";
  
  export default {
    name: "log",
    data() {
      return {
        //预售查询
        quyeryData: {
            // status: "0",
            search: "",
            // lab: "2022-04", //标签
        },
        logData: [],
        pagesData: {
          page: 1,
          pagesize: 20,
          total: 0,
        },
      };
    },
    created() {
      this.handleGetLog();
    },
    methods: {
      handleGetLog() {
  
        let query={
          page: this.pagesData.page,
          pagesize: this.pagesData.pagesize,
        };
  
        if (this.quyeryData.search) query.search=this.quyeryData.search;
  
        presellApi.getPresellEditGoodsLog(query).then(res => {
          const { data }=res.data;
          this.logData=data.data_list;
          this.pagesData.total=data.total || 0;
  
        });
      },
      handleQuery() {
  
        this.pagesData.page=1;
  
        this.handleGetLog();
  
      },
      handleSizeChange(val) {
        this.pagesData.pagesize=val;
        this.handleGetLog();
      },
      handleCurrentChange(val) {
        this.pagesData.page=val;
        this.handleGetLog();
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  