import { render, staticRenderFns } from "./presellConfig.vue?vue&type=template&id=e4b74a32&scoped=true"
import script from "./presellConfig.vue?vue&type=script&lang=js"
export * from "./presellConfig.vue?vue&type=script&lang=js"
import style0 from "./presellConfig.vue?vue&type=style&index=0&id=e4b74a32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4b74a32",
  null
  
)

export default component.exports