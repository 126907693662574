import { render, staticRenderFns } from "./bandcode.vue?vue&type=template&id=33d9084c&scoped=true"
import script from "./bandcode.vue?vue&type=script&lang=js"
export * from "./bandcode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d9084c",
  null
  
)

export default component.exports