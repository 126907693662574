<template>
  <div>
    <el-tabs v-model="activeName" style="padding: 5px;" @tab-click="handleGetLog">
      <el-tab-pane label="商品管理" name="first">
        <div class="pageBody">

          <div class="pageBody_title">
            <div class="pageBody_title_add">
              <el-button type="primary" size="mini" @click="addData">新增</el-button>
            </div>
            <div class="pageBody_title_query">
              预售月份：
              <el-select v-model="quyeryData.act_id" placeholder="预售月份" size="mini" clearable>
                <el-option v-for="item in labelArr" :key="item.id" :label="item.label_time" :value="item.id">
                </el-option>
              </el-select>
              状态：
              <el-select v-model="quyeryData.status" placeholder="请预售状态" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              名称：
              <el-input size="mini" v-model="quyeryData.name2Sku" class="pageBody_title_query_input" placeholder="名称 | sku | 海鼎编码"></el-input>
              &nbsp;
              <el-button type="primary" size="mini" @click="queryClick">查询</el-button>
              <el-button v-show="exportData.showExport==false" type="success" size="mini" @click="exportData.showExport=true">
              导出
              </el-button>
              <!-- <el-button type="warning" size="mini" @click="importPresell">
                》导入
              </el-button> -->

            </div>
          </div>

          <div class="importDIV">
            <!-- <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload> -->
            <el-upload ref="upload" :action="`/a1/upload/file`" :http-request="handleUpload" :show-file-list="false" style="width: auto;display: inline-block;margin-left: 10px;">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <!--  -->
            <el-upload ref="upload" action="#" :http-request="handleUploadRemark" :show-file-list="false" style="width: auto;display: inline-block;margin-left: 10px;">
              <el-button size="small" type="primary">点击上传批量备注</el-button>
            </el-upload>
            <el-button
            style="margin-left:10px ;"
            type="warning"
            size="mini"
            icon="el-icon-download"
            @click="downloadImportDemo"
          >
            下载批量备注模板
          </el-button>
          </div>

          <div v-show="exportData.showExport">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>商品导出</span>
              </div>
              <div>

                <p class="exportP">
                <div>
                  导出信息：
                  <el-checkbox  @change="handleCheckAllChange">全选</el-checkbox>
                  &nbsp;&nbsp;&nbsp;
                  <el-button type="danger" size="mini" @click="handleCheckAllChange2">反选</el-button>
                </div>
                <el-checkbox-group v-model="exportData.checkList">
                  <el-checkbox class="check" label="item_name">商品名称</el-checkbox>
                  <el-checkbox class="check" label="item_en">商品英文名</el-checkbox>
                  <!-- <el-checkbox class="check" label="label_time">标签时间</el-checkbox> -->
                  <el-checkbox class="check" label="item_no">商品编码</el-checkbox>
                  <el-checkbox class="check" label="hd_sku">海鼎编码</el-checkbox>
                  <el-checkbox class="check" label="purchase_spec">规格</el-checkbox>
                  <el-checkbox class="check" label="lot_spec">箱规</el-checkbox>
                  <el-checkbox class="check" label="base_price">配送价</el-checkbox>
                  <el-checkbox class="check" label="sale_price">零售价</el-checkbox>
                  <el-checkbox class="check" label="display_flag">是否上线</el-checkbox>
                  <el-checkbox class="check" label="material">材质</el-checkbox>
                  <el-checkbox class="check" label="material_en">材质(en)</el-checkbox>
                  <el-checkbox class="check" label="lwh">尺寸</el-checkbox>
                  <el-checkbox class="check" label="lwh_en">尺寸(EN)</el-checkbox>
                  <el-checkbox class="check" label="weight">重量</el-checkbox>
                  <el-checkbox class="check" label="weight_en">重量(EN)</el-checkbox>
                  <el-checkbox class="check" label="stuffing">填充物</el-checkbox>
                  <el-checkbox class="check" label="stuffing_en">填充物(en)</el-checkbox>
                  <el-checkbox class="check" label="features">产品特点</el-checkbox>
                  <el-checkbox class="check" label="features_en">产品特点(EN)</el-checkbox>
                  <el-checkbox class="check" label="usage">使用方法</el-checkbox>
                  <el-checkbox class="check" label="usage_en">使用方法(EN)</el-checkbox>
                  <el-checkbox class="check" label="notes">注意事项</el-checkbox>
                  <el-checkbox class="check" label="notes_en">注意事项(EN)</el-checkbox>
                  <el-checkbox class="check" label="caution">警告语</el-checkbox>
                  <el-checkbox class="check" label="caution_en">警告语(EN)</el-checkbox>
                  <el-checkbox class="check" label="standard">执行标准</el-checkbox>
                  <el-checkbox class="check" label="expiration">保质期</el-checkbox>
                  <el-checkbox class="check" label="hs_code">海关编码</el-checkbox>
                  <el-checkbox class="check" label="ban_area">禁配地区</el-checkbox>
                  <el-checkbox class="check" label="target">起订数量</el-checkbox>
                  <el-checkbox class="check" label="arrival">到货时间-时间戳</el-checkbox>
                  <el-checkbox class="check" label="deadline">截止时间-时间戳</el-checkbox>
                  <el-checkbox class="check" label="order_num">订单数量</el-checkbox>
                  <el-checkbox class="check" label="lead_time">生产周期</el-checkbox>
                  <el-checkbox class="check" label="collect">认证类型</el-checkbox>
                  <el-checkbox class="check" label="sku_status">商品sku状态</el-checkbox>
                  <el-checkbox class="check" label="qty">到货数</el-checkbox>
                  <el-checkbox class="check" label="of_type">产品类型</el-checkbox>
                  <el-checkbox class="check" label="remark">备注</el-checkbox>
                </el-checkbox-group>
                </p>

                <p class="exportP">
                  状态：
                  <el-select v-model="exportData.status" placeholder="请预售状态" size="mini">
                    <el-option v-for="item in exportData.goodsStatus" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </p>

                <p class="exportP">
                  时间：
                  <el-date-picker value-format="timestamp" v-model="exportData.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </p>

                <p class="exportP">
                  <el-button @click="exportDataFun" type="primary" size="mini" style="margin-left: 47px;">导出
                  </el-button>
                  <el-button type="warning" size="mini" @click="canselExport">取消</el-button>
                </p>

              </div>
            </el-card>

          </div>

          <div class="pageBody_body">
            <el-table :data="presellData" border style="width: 100%" height="730">
              <el-table-column fixed prop="id" label="ID" align="center" width="60">
              </el-table-column>
              <el-table-column fixed prop="purchase_spec" label="商品图片" align="center">
                <template slot-scope="{row}">
                  <viewer>
                    <a>
                      <img :src="`${row.img}`" v-if="row.use_yum==0" height="50" />
                      <img :src="`${row.yum_base_img}`" v-else height="50" />
                    </a>
                  </viewer>
                </template>
              </el-table-column>

              <el-table-column fixed prop="item_name" label="商品类型" width="160" align="center">
                <template slot-scope="{row}">
                  <div>
                    <span v-if="row.of_type > 0 ">{{presellTypes2Keys[row.of_type]}}</span>
                    <span v-else>未定义</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column fixed prop="item_name" label="商品名称" width="280">
                <template slot-scope="scope">
                  <div>
                    中文:{{ scope.row.item_name }}
                  </div>
                  <div>
                    英文:{{ scope.row.item_en }}
                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column fixed prop="item_no" label="预售编码" align="center" width="130">
              </el-table-column> -->

              <el-table-column prop="ban_area" label="禁配地区" align="center" width="150">
              </el-table-column>
              <el-table-column prop="sku_status" label="编码" align="center" width="150">
                <template slot-scope="{row}">
                  <p>预售:{{ row.item_no }}</p>
                  <p>海鼎:{{ row.hd_sku ? row.hd_sku : "未定义" }}</p>
                </template>
              </el-table-column>

              <!-- <el-table-column prop="label_time" label="标签" align="center"> -->

              <!--</el-table-column>-->

              <el-table-column prop="purchase_spec" label="规格" align="center" width="50">
              </el-table-column>

              <el-table-column prop="target" label="最新预售活动数据" align="center" width="140">
                <template slot-scope="{row}">
                  <div>起订数量：{{ row.target }}</div>
                  <div>最新预售订单数：{{ row.act_order_num }}</div>
                  <div>预售完成比例：<span v-if="(row.act_order_num / row.target * 100)>0">{{ (row.act_order_num / row.target * 100).toFixed(0) }}</span> %</div>
                </template>
              </el-table-column>

              <el-table-column prop="lot_spec" label="价格" align="center" width="120">
                <template slot-scope="scope">
                  <div>配送价：￥<span v-if="scope.row.base_price!= undefined ">{{ scope.row.base_price.toFixed(2) }}</span></div>
                  <div>零售价：￥<span v-if="scope.row.sale_price!= undefined ">{{ scope.row.sale_price.toFixed(2) }}</span></div>
                </template>
              </el-table-column>

              <el-table-column prop="lead_time" label="周期" align="center" width="60">
              </el-table-column>

              <el-table-column prop="qty" label="到货数" align="center" width="60">
              </el-table-column>

              <el-table-column prop="" label="商品状态" align="center" width="80">
                <template slot-scope="scope">
                  <!-- <el-switch v-model="scope.row.display_flag" @change="changeUp($event, scope.row, 'in_lang')"
                    active-text="上线" inactive-text="下线" :active-value="1" :inactive-value="0">
                  </el-switch> -->
                  <span v-if="scope.row.display_flag==1">上线</span>
                  <span v-if="scope.row.display_flag==0">下线</span>
                </template>
              </el-table-column>

              <el-table-column prop="sku_status" label="预售状态" align="center">
                <template slot-scope="{row}">
                  <span v-if="row.sku_status==1" style="color: orange;">{{ auditType[row.sku_status] }}</span>
                  <span v-if="row.sku_status==2" style="color: red;">{{ auditType[row.sku_status] }}</span>
                  <span v-if="row.sku_status==0">{{ auditType[row.sku_status] }}</span>
                </template>
              </el-table-column>

              <el-table-column label="备注" align="center" width="130">
                <template slot-scope="{row}">
                  <el-input v-model="row.remark" @change="setRemark(row)" />
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" width="280">
                <template slot-scope="scope">
                  <el-button v-if="[2,7,52,54].indexOf(loginid) >=0 " @click="editPresell(scope.row)" type="primary" size="mini">
                    编辑
                  </el-button>
                  <el-button type="danger" size="mini" @click="dleRow(scope.row)">删除</el-button>
                  <el-button type="success" size="mini" @click="lookOrder(scope.row)">查看订单</el-button>

                  <div v-if="scope.row.sku_status==0">
                    <el-button @click="auditGoods(scope.row,1)" type="text" size="mini">
                      通过
                    </el-button>
                    <el-button @click="auditGoods(scope.row,2)" type="text" size="mini">
                      不通过
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageData.page" :page-sizes="[20, 30, 40,50]" :page-size="pageData.page_size" layout="sizes, prev, pager, next" :total="pageData.total">
            </el-pagination>
          </div>

          <div class="addDiv">
            <el-drawer title="我是标题" :with-header="false" size="45%" :visible.sync="addDiv.drawer" :direction="addDiv.direction">
              <h3 class="addDiv_title">{{ addDiv.title }}</h3>

              <div class="addDiv_ct">
                <el-form ref="form" :model="form" label-width="80px" class="addDiv_ct_el" style="margin-bottom:300px;">
                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">中文：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.item_name" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">英文：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.item_en" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">产品类型：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-select v-model="form.of_type" clearable placeholder="请选择">
                          <el-option v-for="item in presellTypes" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">商品编码：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.item_no" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">关键字：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.keyword" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">规格：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.purchase_spec" class="addDiv_form_el_item_input" type="number">
                        </el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">箱规：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.lot_spec" class="addDiv_form_el_item_input" type="number"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">配送价：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.base_price" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">零售价：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.sale_price" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">是否上线：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-switch v-model="form.display_flag" active-text="上线" inactive-text="下线" :active-value="1" :inactive-value="0">
                        </el-switch>
                      </p>
                    </div>

                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">材质：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.material" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">材质(en)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.material_en" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">尺寸：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.lwh" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">尺寸(EN)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.lwh_en" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">重量：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.weight" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">重量(EN)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.weight_en" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">填充物 ：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.stuffing" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">填充物(en)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.stuffing_en" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">产品特点：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.features" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">产品特点(EN)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.features_en" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">使用方法：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.usage" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">使用方法(EN)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.usage_en" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">注意事项：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.notes" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">注意事项(EN)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.notes_en" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">警告语：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.caution" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">警告语(EN)：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.caution_en" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">执行标准：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.standard" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">保质期：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.expiration" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">海关编码：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.hs_code" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">禁配地区：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.ban_area" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">主图视频：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.base_media" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">详情图视频：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.info_media" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">图片规则：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-switch v-model="form.use_yum" active-text="云图片" inactive-text="本地上传" :active-value="1" :inactive-value="0">
                        </el-switch>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div" v-if="form.use_yum==1">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">云产品主图：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.yum_base_img" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">云详情主图：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.yum_info_img" class="addDiv_form_el_item_input">
                        </el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div" v-if="form.use_yum==0">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">主图路径：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-upload class="upload-demo" :action="uploadeConfig.uploadUrl" :on-success="handleSuccessBase" :headers="uploadHeaders" :show-file-list="false">
                          <el-button size="mini" type="primary">点击上传:jpg/png图片</el-button>
                        </el-upload>
                      </p>
                    </div>
                    <div class="addDiv_ct_el_div_row">
                      <div v-if="form.img" style="border:1px solid #ccc;margin-top: 5px; display: flex;">
                        <img height="100" :src="form.img" />
                        <el-button @click="deleteImgBase" type="text" style="background: #ccc; color: #000;">
                          删除
                        </el-button>
                      </div>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div" v-if="form.use_yum==0">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">详情图路径：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-upload class="upload-demo" :action="uploadeConfig.uploadUrl" :on-success="handleSuccessInfo" :headers="uploadHeaders" :show-file-list="false">
                          <el-button size="mini" type="primary">点击上传:jpg/png图片</el-button>
                        </el-upload>
                      </p>
                    </div>
                    <div class="addDiv_ct_el_div_row">
                      <div v-if="form.details_img" style="border:1px solid #ccc;margin-top: 5px; display: flex;">
                        <img height="100" :src="form.details_img" />
                        <el-button @click="deleteImgInfo" type="text" style="background: #ccc; color: #000;">
                          删除
                        </el-button>
                      </div>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">起订数量：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.target" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">到货时间：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <!-- <el-input v-model="form.arrival" class="addDiv_form_el_item_input"></el-input> -->
                        <el-date-picker v-model="form.arrival" type="date" placeholder="选择日期" value-format="timestamp">
                        </el-date-picker>
                      </p>
                    </div>

                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">截止时间：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <!-- <el-input v-model="form.deadline" class="addDiv_form_el_item_input"></el-input> -->
                        <el-date-picker v-model="form.deadline" type="date" placeholder="选择日期">
                        </el-date-picker>
                      </p>
                    </div>
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">生产周期：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.lead_time" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                    <!-- <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">日期标签：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-select v-model="form.label_time">
                          <el-option v-for="item in labelArr" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </p>
                    </div> -->
                  </div>

                  <div class="addDiv_ct_el_div">
                    <div class="addDiv_ct_el_div_row">
                      <p class="addDiv_ct_el_div_row_name">认证文件：</p>
                      <p class="addDiv_ct_el_div_row_inp">
                        <el-input v-model="form.collect" class="addDiv_form_el_item_input"></el-input>
                      </p>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="form_foot">
                <el-button size="mini" type="primary" @click="submit2presell" class="form_foot_submit">提交
                </el-button>
                <el-button size="mini" type="danger" @click="closeSubmit">关闭</el-button>
                <input type="hidden" v-model="submitType" />
              </div>
            </el-drawer>
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane label="绑定管理" name="second">
        <bandcode></bandcode>
      </el-tab-pane>

      <!--  -->
      <el-tab-pane label="分类管理" name="three">
        <presellType></presellType>
      </el-tab-pane>

      <!--  -->
      <el-tab-pane label="预售配置管理" name="four">
        <presellConfig></presellConfig>
      </el-tab-pane>
      <!--  -->
      <el-tab-pane label="商品修改记录" name="five">
        <editGoodsLog ref="editGoodsLog"></editGoodsLog>
      </el-tab-pane>
      <!--  -->
      <el-tab-pane label="批量禁配设置" name="six">
        <editBanArea ref="editBanArea"></editBanArea>
      </el-tab-pane>
      <!--  -->
      <el-tab-pane label="预售活动管理" name="seven">
        <presellActivites ref="presellActivites"></presellActivites>
      </el-tab-pane>
    </el-tabs>

    <!-- 查看订单详情  -->
    <div>
      <el-drawer title="我是标题" size="70%" :with-header="false" :visible.sync="showGoodsToOrder.drawer" :direction="showGoodsToOrder.direction" :before-close="orderHandleClose">
        <orderInfo :sku="showGoodsToOrder.sku"></orderInfo>
      </el-drawer>
    </div>

  </div>
</template>

<script>
import common from "@/common/mixins/common.js";
import orderInfo from "./showOrderByGoods";
import bandcode from "./bandcode";
import presellType from "./presellType.vue";
import presellConfig from "./presellConfig.vue"
import editGoodsLog from "./editGoodsLog.vue"
import editBanArea from "./editBanArea.vue"
import presellActivites from "./presellActivites.vue"
import presellApi from "@/api/admin/presell";


export default {
  mixins: [common],
  components: {
    orderInfo,
    bandcode,
    presellType,
    presellConfig,
    editGoodsLog,
    editBanArea,
    presellActivites,
  },
  data() {
    return {
      activeName: "first",
      labelArr: [],
      presellTypes: [],
      presellTypes2Keys: [], //key 2 name

      //显示商品订单
      showGoodsToOrder: {
        drawer: false,
        direction: "rtl",
        sku: "",
      },
      uploadHeaders: {
        Authorization: JSON.parse(window.sessionStorage.getItem("token")),
      },
      // 当前登陆的用户ID
      loginid:0,
      //上传参数
      uploadeConfig: {
        uploadUrl: "",
        baseImg: "",
        infoImg: "",
      },
      //状态刷选
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "预售中",
        },
        {
          value: "2",
          label: "结束预售",
        },
      ],
      value: "",
      presellData: [],
      pageData: {
        page: 1,
        page_size: 20,
        total: 100,
      },
      addDiv: {
        drawer: false,
        direction: "rtl",
        title: "添加预售商品",
      },
      form: {},
      submitType: "add",
      //预售查询
      quyeryData: {
        status: "0",
        name2Sku: "",
        act_id: "", //标签
      },
      auditType: {
        0: "待审核",
        1: "预售成功",
        2: "预售失败",
      },
      exportData: {
        checkList: [],
        status: "",
        time: [],
        showExport: false,
        goodsStatus: [
          {
            value: "0",
            label: "待审核",
          },
          {
            value: "1",
            label: "预售成功",
          },
          {
            value: "2",
            label: "预售失败",
          },
        ],
      },
      //导出全部字段命
      exportAllValues2Name: [
        "item_name",
        "item_en",
        "label_time",
        "item_no",
        "hd_sku",
        "purchase_spec",
        "lot_spec",
        "base_price",
        "sale_price",
        "display_flag",
        "material",
        "material_en",
        "lwh",
        "lwh_en",
        "weight",
        "weight_en",
        "stuffing",
        "stuffing_en",
        "features",
        "features_en",
        "usage",
        "usage_en",
        "notes",
        "notes_en",
        "caution",
        "caution_en",
        "standard",
        "expiration",
        "hs_code",
        "ban_area",
        "target",
        "arrival",
        "deadline",
        "order_num",
        "lead_time",
        "collect",
        "sku_status",
        "qty",
        "of_type",
        "remark",
      ],
    };
  },
  created() {
    this.getPresellData();
    this.setUploadUrl();
    this.getPresellType();
    this.getActList();//获取预售活动
    const adminInfoStr = sessionStorage.getItem("user")
    let adminInfo = {}
    if (adminInfoStr) adminInfo = JSON.parse(adminInfoStr)
    this.loginid = adminInfo.admin_info.id
  },
  methods: {
    handleGetLog(e){
      if(parseInt(e.index)===4){
        this.$refs.editGoodsLog.handleGetLog()
      }
    },
    /* 下载例子 */
    downloadImportDemo() {
      window.location.href = this.$root.DownUrl + '/c1/down/presell/ImportGoodsRemark'
    },
    // 点击导入预售商品备注
    handleUploadRemark(e){
      const formdata = new FormData();
      formdata.append("file", e.file);
      // this.$alert("正在上传，请稍候...", {
      //   showConfirmButton: false,
      //   showClose: false,
      // });
      this.$axios
        .post("/a1/presell/ImportGoodsRemarkExcel", formdata)
        .then((res) => {
          console.log(res);
          // const { status } = res;
          // if (status == "200") {
          //   const { data } = res.data;
          //   this.uploadFileList.push({
          //     url: data,
          //   });
          // }
        })
        .finally(() => {
          this.$msgbox.close();
        });
    },
    //点击导入预售数据
    handleUpload(e) {
      const formdata = new FormData();
      formdata.append("file", e.file);
      // this.$alert("正在上传，请稍候...", {
      //   showConfirmButton: false,
      //   showClose: false,
      // });
      this.$axios
        .post("/a1/presell/importExcel", formdata)
        .then((res) => {
          console.log(res);
          // const { status } = res;
          // if (status == "200") {
          //   const { data } = res.data;
          //   this.uploadFileList.push({
          //     url: data,
          //   });
          // }
        })
        .finally(() => {
          this.$msgbox.close();
        });
    },
    //反选导出
    handleCheckAllChange2() {
      var newArr = []
      for (const key in this.exportAllValues2Name) {
        const theName = this.exportAllValues2Name[key];
        if (this.exportData.checkList.indexOf(theName, 0) < 0) {
          newArr.push(theName)
        }
      }
      this.exportData.checkList = newArr
    },
    //全选导出
    handleCheckAllChange(val) {
      const varAllValues = this.exportAllValues2Name;
      this.exportData.checkList = val ? varAllValues : [];
    },

    //获取到绑定列表
    getActList() {
        presellApi.getAllActList().then((res) => {
          var data = res.data.data
          this.labelArr = data.data_list;
        });
    },

    //设置海鼎sku
    SetHdSku(row) {
      //console.log(row)
      //设置海鼎sku
      let putData = {
        sku: row.hd_sku,
        id: row.id,
      };
      this.$axios.put(`/a1/presell/SetGoodsHdSku`, putData).then((res) => {
        this.$message.success("修改完成");
      });
    },
    //设置备注
    setRemark(row) {
      //console.log(row.id, ":",row.remark);
      const postData = {
        remark: row.remark,
      };
      this.$axios.put(`/a1/presell/set/remark/${row.id}`, postData).then((res) => {
        // console.log(res.data);
      });
    },
    //导出商品数据
    exportDataFun() {
      //导出数据
      let fieldArr = [];
      for (let i = 0; i < this.exportData.checkList.length; i++) {
        fieldArr.push(this.exportData.checkList[i]);
      }
      let postData = {
        field: fieldArr,
        goods_status: this.exportData.status,
        begin_time: this.exportData.time[0] / 1000 + "",
        end_time: this.exportData.time[1] / 1000 + "",
      };
      this.$axios.post("/a1/presell/CreateExportGoods", postData).then((res) => {
        let dowloadKey = res.data.data.to_key;
        //开始下载
        location.href = this.$root.DownUrl + "/c1/down/" + dowloadKey;
      });
    },
    //取消导出
    canselExport() {
      this.exportData.showExport = false;
      this.exportData.status = "";
      this.exportData.goodsStatus = [];
    },
    //审核商品
    auditGoods(row, status) {
      if (confirm("确定该操作吗")) {
        let putData = {
          sku: row.item_no,
          status: status,
        };
        this.$axios.put("/a1/presell/setGoods", putData).then((res) => {
          this.$message.success("审核成功");
          this.getPresellData();
        });
      }
    },
    lookOrder(row) {
      this.showGoodsToOrder.drawer = true;
      this.showGoodsToOrder.sku = row.item_no;
    },
    //显示订单关闭
    orderHandleClose(done) {
      done();
    },

    //删除文件
    dleRow(row) {
      if (confirm("确认删除这个预售产品吗")) {
        this.$axios.delete("/a1/presell/" + row.id).then((res) => {
          this.$message.success("删除成功");
          this.getPresellData();
        });
      }
    },
    //删除主图图片
    deleteImgBase() {
      this.form.img = "";
    },
    //上传主图成功时钩子
    handleSuccessBase(response, file, fileList) {
      this.form.img = response.data;
    },
    //删除详情图片
    deleteImgInfo() {
      this.form.details_img = "";
    },
    //上传详情成功时钩子
    handleSuccessInfo(response, file, fileList) {
      this.form.details_img = response.data;
    },

    //设置上传地址参数
    setUploadUrl() {
      this.uploadeConfig.uploadUrl = this.$root.DownUrl + "/a1/upload/file";
    },
    //=======================================================
    //修改预售商品
    editPresell(row) {
      this.form = row;
      // console.log(row)
      let theLeng = this.form.arrival + "";
      if (theLeng.length < 11) {
        this.form.arrival = row.arrival * 1000;
        this.form.deadline = row.deadline * 1000;
      }
      this.addDiv.drawer = true;
      this.submitType = "edit";
    },
    //关闭预售添加
    closeSubmit() {
      this.addDiv.drawer = false;
    },
    //提交预售
    submit2presell() {
      let formData = this.form;
      if (formData.arrival) {
        formData.arrival = formData.arrival / 1000;
      }
      if (formData.deadline) {
        formData.deadline = formData.deadline / 1000;
      }
      if (this.submitType == "add") {
        formData.use_yum = parseInt(formData.use_yum);
        this.$axios
          .post("/a1/presell/add", formData)
          .then((res) => {
            this.$message.success("添加成功");
            this.addDiv.drawer = false;
            this.getPresellData();
            // 更新日志
            this.$refs.editGoodsLog.handleGetLog()
          })
          .catch(() => {
            this.$message.error("添加失败，请检查SKU是否重复");
          });
      } else {
        if (this.form.id > 0) {
          let id = formData.id;
          for (let key in formData) {
            formData[key] = formData[key] + "";
          }
          formData.use_yum = parseInt(formData.use_yum);

          this.$axios
            .put("/a1/presell/" + id, formData)
            .then((res) => {
              this.$message.success("修改成功");
              this.addDiv.drawer = false;
              this.getPresellData();
              // 更新日志
              this.$refs.editGoodsLog.handleGetLog()
            })
            .catch(() => {
              this.$message.error("修改失败");
            });
        }
      }

    },
    addData() {
      this.addDiv.drawer = true;
      this.submitType = "add";
      this.form = {};
    },
    //查询
    queryClick() {
      this.getPresellData();
    },
    //获取预售数据
    getPresellData() {
      let queryParams = {
        page: this.pageData.page,
        page_size: this.pageData.page_size,
      };
      queryParams.status = this.quyeryData.status;
      queryParams.name_sku = this.quyeryData.name2Sku;
      queryParams.act_id = this.quyeryData.act_id;
      // console.log(queryParams);
      this.$axios
        .get("/a1/presell/list", {
          params: queryParams,
        })
        .then((res) => {
          const { data } = res.data;
          this.presellData = data.data;
          this.pageData.total = data.total;
          this.pageData.page = data.page;
          this.pageData.page_size = data.page_size;
        });
    },

    //获取产品类型
    getPresellType() {
      this.$axios.get(`/a1/presell/preType`).then((res) => {
        let resData = res.data.data;
        let presellTypes = [];
        this.presellTypes2Keys = [];
        for (let key in resData) {
          let theData = {
            label: resData[key].name,
            value: resData[key].id,
          };
          presellTypes.push(theData);
          this.presellTypes2Keys[resData[key].id] = resData[key].name;
        }
        this.presellTypes = presellTypes;
      });
    },

    //修改
    changeUp: function (callback, row) {
      // console.log(row);
    },
    handleSizeChange(val) {
      this.pageData.page_sze = val;
      this.getPresellData();
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getPresellData();
      //console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.pageBody {
  margin: 10px;


  &_title {
    padding-bottom: 10px;
    border-bottom: 1px solid skyblue;
    display: flex;

    &_query {
      padding-left: 20px;

      &_input {
        width: 200px !important;
      }
    }
  }

  &_body {
  }
}

.addDiv {
  &_title {
    height: 50px;
    line-height: 50px;
    background: #218da0;
    color: white;
    text-indent: 1em;
    font-weight: 800;
    font-family: "黑体";
  }

  &_ct {
    height: 79%;
    overflow: auto;
  }

  &_ct_el {
    &_div {
      display: flex;
      padding: 3px;

      &_row {
        display: flex;
        width: 50%;
        align-items: center;

        &_name {
          text-align: right;
          width: 150px;
        }

        &_inp {
          width: 90%;
        }
      }
    }
  }

  .form_foot {
    width: 100%;
    background: white;
    border: 1px solid cadetblue;
    position: absolute;
    bottom: 1px;
    padding: 10px;

    &_submit {
      background: #218da0;
      border: 1px solid #218da0;
    }
  }

}

.exportP {
  //border: 1px solid red;
  margin: 10px;
}
.importDIV{
  border:1px solid #218da0;
  padding:8px;
  border-radius:10px;
  margin-top:10px;
  margin-bottom: 10px;
}
</style>
