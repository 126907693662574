<template>
    <div>
        <div style="padding-left: 10px; margin-top: 10px">
			<el-button @click="addRowShow()" type="danger" size="mini">添加预售活动</el-button>
		</div>

    <el-table :data="tableData" width="100%" height="660">
        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="act_title" label="活动标题"></el-table-column>
        <el-table-column prop="label_time" label="标签时间"></el-table-column>
        <el-table-column prop="start_date" label="动画、活动开始时间">
            <template slot-scope="scope">
              <span>{{ new Date(scope.row.start_date*1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="end_date" label="动画、活动结束时间" >
            <template slot-scope="scope">
              <span>{{ new Date(scope.row.end_date*1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="" label="操作" align="center" width="280px">
          <template v-slot="{ row }">
                <el-button type="primary" plain @click="handleEdit(row)" size="mini">编辑</el-button>
                <el-button type="warning" plain @click="handleEditGoods(row)" size="mini">绑定商品</el-button>
                <el-button type="success" plain @click="handleGoodsList(row)" size="mini">查看商品</el-button>
              </template>
        </el-table-column>
      </el-table>

      <!-- 活动管理 -->
    <el-dialog :title="typeTitle" :visible.sync="dialogVisible" width="30%">
        <div>
            <el-form ref="form" label-width="120px">
                <el-form-item label="活动标题">
                  <el-input v-model="saveData.act_title"></el-input>
                </el-form-item>
                <!--  -->
                <el-form-item label="活动标签时间">
                  <el-date-picker
                      v-model="saveData.label_time"
                      type="month"
                      placeholder="选择日期"
                      value-format="yyyy-MM"
                      clearable>
                  </el-date-picker>
                </el-form-item>
                <!--  -->
                <el-form-item label="动画、活动开始时间">
                  <el-date-picker
                      v-model="saveData.start_date"
                      type="datetime"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      clearable>
                  </el-date-picker>
                </el-form-item>
                <!--  -->
                <el-form-item label="动画、活动结束时间">
                  <el-date-picker
                      v-model="saveData.end_date"
                      type="datetime"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      clearable>
                  </el-date-picker>
                </el-form-item>
                <!--  -->
                <el-form-item label="备注">
                  <el-input v-model="saveData.remark"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitFormData">提 交</el-button>
        </span>
    </el-dialog>

      <!-- 添加关联活动商品 -->
      <el-drawer  title="添加活动关联商品" :visible.sync="dialogVisibleGoods" direction="rtl" size="40%">
          <div>
              <el-form ref="form" label-width="120px">
                  <el-form-item label="活动标题" class="act_goods_input">
                    <el-input v-model="saveGoodsData.act_title" disabled></el-input>
                  </el-form-item>
                  <!--  -->
                  <el-form-item label="活动标签时间" class="act_goods_input">
                    <el-date-picker
                        v-model="saveGoodsData.label_time"
                        type="month"
                        placeholder="选择日期"
                        value-format="yyyy-MM"
                        clearable
                        disabled>
                    </el-date-picker>
                  </el-form-item>
                  <!--  -->
                  <el-form-item label="动画、活动开始时间" class="act_goods_input">
                    <el-date-picker
                        v-model="saveGoodsData.start_date"
                        type="datetime"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        clearable
                        disabled>
                    </el-date-picker>
                  </el-form-item>
                  <!--  -->
                  <el-form-item label="动画、活动结束时间" class="act_goods_input">
                    <el-date-picker
                        v-model="saveGoodsData.end_date"
                        type="datetime"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        clearable
                        disabled>
                    </el-date-picker>
                  </el-form-item>
                  <!--  -->
                  <el-form-item label="备注" class="act_goods_input">
                    <el-input v-model="saveGoodsData.remark" disabled></el-input>
                  </el-form-item>
                  <!-- 添加关联商品  -->
                  <el-form-item label="关联预售商品" class="act_goods_input">
                      <span>示例：</span>
                      <pre>
                        31202307001xx
                        31202309018xx
                        31202309212xx
                      </pre>
                      <el-input type="textarea" rows="18" v-model="saveGoodsData.skus"></el-input>
                  </el-form-item>
              </el-form>
          </div>
          <div class="form_foot">
            <el-button size="mini" type="primary" @click="submit2presell" class="form_foot_submit">提交
            </el-button>
            <el-button size="mini" type="danger" @click="dialogVisibleGoods= false">关闭</el-button>
          </div>
      </el-drawer>
      <!--  -->

      <!-- 查看活动商品 -->
      <el-drawer  title="查看活动关联商品" :visible.sync="dialogVisibleEditGoods" direction="rtl" size="70%">
          <div>
            <div style="padding: 10px">
              <el-button type="warning" size="mini" @click="batchGoodsDel">批量删除</el-button>
              <!-- <el-button type="danger" size="mini">全部删除</el-button> -->
            </div>
            <!--  -->
            <el-table :data="presellGoodsData" border style="width: 100%" height="730" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" align="center">
              </el-table-column>
              <el-table-column fixed prop="PresellActivitiesGoods.id" label="ID" align="center" width="60">
              </el-table-column>
              <el-table-column fixed prop="Presell.purchase_spec" label="商品图片" align="center">
                <template slot-scope="{row}">
                  <img :src="`${row.Presell.img}`" v-if="row.Presell.use_yum==0" height="50" />
                  <img :src="`${row.Presell.yum_base_img}`" v-else height="50" />
                </template>
              </el-table-column>

              <el-table-column fixed prop="Presell.item_name" label="商品类型" width="160" align="center">
                <template slot-scope="{row}">
                  <div>
                    <span v-if="row.Presell.of_type > 0 ">{{presellTypes2Keys[row.Presell.of_type]}}</span>
                    <span v-else>未定义</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column fixed prop="Presell.item_name" label="商品名称" width="280">
                <template slot-scope="scope">
                  <div>
                    中文:{{ scope.row.Presell.item_name }}
                  </div>
                  <div>
                    英文:{{ scope.row.Presell.item_en }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="Presell.sku_status" label="编码" align="center" width="150">
                <template slot-scope="{row}">
                  <p>预售:{{ row.Presell.item_no }}</p>
                  <p>海鼎:{{ row.Presell.hd_sku ? row.Presell.hd_sku : "未定义" }}</p>
                </template>
              </el-table-column>

              <el-table-column prop="Presell.purchase_spec" label="规格" align="center" width="50">
              </el-table-column>

              <el-table-column prop="Presell.target" label="起订数量" align="center" width="120">
                <template slot-scope="{row}">
                  <div>起订数量：{{ row.Presell.target }}</div>
                  <div>订单数量：{{ row.PresellActivitiesGoods.act_order_num }}</div>
                  <div>完成比例：<span v-if="(row.PresellActivitiesGoods.act_order_num / row.Presell.target * 100)>0">{{ (row.PresellActivitiesGoods.act_order_num / row.Presell.target * 100).toFixed(0) }}</span> %</div>
                </template>
              </el-table-column>

              <el-table-column prop="Presell.lot_spec" label="价格" align="center" width="120">
                <template slot-scope="scope">
                  <div>配送价：￥<span v-if="scope.row.Presell.base_price!= undefined ">{{ scope.row.Presell.base_price.toFixed(2) }}</span></div>
                  <div>零售价：￥<span v-if="scope.row.Presell.sale_price!= undefined ">{{ scope.row.Presell.sale_price.toFixed(2) }}</span></div>
                </template>
              </el-table-column>

              <el-table-column prop="Presell.lead_time" label="周期" align="center" width="60">
              </el-table-column>

              <el-table-column prop="Presell.qty" label="到货数" align="center" width="60">
              </el-table-column>

              <el-table-column prop="" label="商品状态" align="center" width="80">
                <template slot-scope="scope">
                  <!-- <el-switch v-model="scope.row.display_flag" @change="changeUp($event, scope.row, 'in_lang')"
                    active-text="上线" inactive-text="下线" :active-value="1" :inactive-value="0">
                  </el-switch> -->
                  <span v-if="scope.row.Presell.display_flag==1">上线</span>
                  <span v-if="scope.row.Presell.display_flag==0">下线</span>
                </template>
              </el-table-column>

              <el-table-column prop="Presell.sku_status" label="预售状态" align="center">
                <template slot-scope="{row}">
                  <span v-if="row.Presell.sku_status==1" style="color: orange;">{{ auditType[row.Presell.sku_status] }}</span>
                  <span v-if="row.Presell.sku_status==2" style="color: red;">{{ auditType[row.Presell.sku_status] }}</span>
                  <span v-if="row.Presell.sku_status==0">{{ auditType[row.Presell.sku_status] }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="Presell.remark" label="备注" align="center" width="130">
              </el-table-column>
            </el-table>
            <el-pagination
              style="padding-top: 15px"
              @size-change="handleActivityGoodsSizeChange"
              @current-change="handleActivityGoodsCurrentChange"
              :current-page.sync="presellActivityGoodsPage.page"
              :page-sizes="[20, 50, 80, 100, 200]"
              :page-size="presellActivityGoodsPage.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="presellActivityGoodsPage.total">
            </el-pagination>
          </div>
      </el-drawer>
      <!--  -->
      <div>
        <el-pagination
        style="padding-top: 15px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pages.page"
        :page-sizes="[20, 50, 80, 100, 200]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.total">
      </el-pagination>
      </div>
    </div>
  </template>

  <script>
  import presellApi from "@/api/admin/presell";

  export default {
    name: "presellActivites",
    data() {
      return {
        formId:"",
        saveData: {
          label_time:"",
          start_date: "",
          end_date:"",
          remark:"",
          act_title:"",
        },
        // 添加活动商品
        dialogVisibleGoods:false,
        saveGoodsData:{
          label_time:"",
          start_date: "",
          end_date:"",
          remark:"",
          act_title:"",
          // 实际需要提交的字段
          skus:"",
          act_id:"",
        },
        dialogVisible: false,
        dialogVisibleEditGoods:false,
        currentId:0,
        presellGoodsData:[],//活动关联的预售商品列表
        presellActData:{},//活动信息
        multipleSelection:[],//批量删除活动商品
        presellTypes: [],
        presellTypes2Keys: [], //key 2 name
        auditType: {
          0: "待审核",
          1: "预售成功",
          2: "预售失败",
        },
        tableData: [],
        pages: {
          page: 1,
          page_size: 10,
          total: 50,
        },
        submitType:"add",
        typeTitle:"添加活动",
        presellActivityGoodsPage:{
          page:1,
          pagesize:20,
          total:0
        }
      };
    },
    created() {
      this.getList();
      this.getPresellType();
    },
    watch:{
      'dialogVisible'(val){
        if(val === false){
          this.clearData()
        }
      },
      'dialogVisibleGoods'(val){
        if(val === false){
          this.clearGoodsData()
        }
      }
    },
    methods: {
        addRowShow() {
				  this.dialogVisible = true;
          this.submitType = "add"
        },

        //获取产品类型
        getPresellType() {
          presellApi.getPresellType().then((res) => {
            let resData = res.data.data;
            let presellTypes = [];
            this.presellTypes2Keys = [];
            for (let key in resData) {
              let theData = {
                label: resData[key].name,
                value: resData[key].id,
              };
              presellTypes.push(theData);
              this.presellTypes2Keys[resData[key].id] = resData[key].name;
            }
            this.presellTypes = presellTypes;
          });
        },

        //提交Ip活动
			submitFormData() {
				let submitType = this.submitType;
				let saveData = this.saveData
				if (submitType == "add") {
					this.addPresellType(saveData);
				}
				if (submitType == "update") {
					this.putPresellType(saveData);
				}
			},
            //添加
			addPresellType(psotData) {
				//添加IP活动
				this.$axios
					.post(`/a1/presellActivites/addAct`, psotData)
					.then((res) => {
						if (res.error == 1) {
              this.$message.error(res.message)
            }else{
              this.$message.success("添加成功");
						  this.getList();
						  this.dialogVisible = false;
            }
					})
					.catch((resErr) => {
						this.$message.error("添加失败："+resErr.response.data.message);
					})
			},

      //修改
			putPresellType(putData) {
				if (this.formId > 0) {
          putData.id = this.formId
					//修改参数
					this.$axios
						.put(`/a1/presellActivites/updateAct/${this.formId}`, putData)
						.then((res) => {
              if(res.error==1){
                this.$message.error(res.message)
              }else{
                this.$message.success("修改成功");
							  this.getList();
						    this.dialogVisible = false;
              }

						})
						.catch((resErr) => {
							this.$message.error("修改失败："+resErr.response.data.message);
						})
				}
			},
      //当前页变化
      handleCurrentChange(val) {
        this.pages.page = val;
        this.getList();
      },
      handleSizeChange(val) {
        this.pages.page_size=val;
        this.getList();
      },



      //编辑
      handleEdit(row){
        this.formId = row.id
        this.submitType = "update"
        this.dialogVisible = true
        this.saveData.label_time = row.label_time
        this.saveData.start_date = row.start_date ? new Date(row.start_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') : ''
        this.saveData.end_date = row.end_date ? new Date(row.end_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') : ''
        this.saveData.remark = row.remark
        this.saveData.act_title = row.act_title
      },



      //获取到绑定列表
      getList() {
        presellApi.getPresellActList({
            page: this.pages.page,
            page_size: this.pages.page_size,
          }).then((res) => {
          var data = res.data.data
          this.tableData = data.data_list;
          this.pages.total = data.data_list.total;
        });
      },


      // 清空数据
      clearData() {
        for (const key in this.saveData) {
          this.saveData[key] = ''
        }
      },

      // 清空预售商品数据
      clearGoodsData(){
        for (const key in this.saveGoodsData) {
          this.saveGoodsData[key] = ''
        }
      },

      // 添加活动商品
      handleEditGoods(row){
          this.dialogVisibleGoods = true
          this.saveGoodsData.label_time = row.label_time
          this.saveGoodsData.start_date = row.start_date ? new Date(row.start_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') : ''
          this.saveGoodsData.end_date = row.end_date ? new Date(row.end_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') : ''
          this.saveGoodsData.remark = row.remark
          this.saveGoodsData.act_title = row.act_title
          this.saveGoodsData.act_id = row.id
      },

      // 查看活动商品列表
      handleGoodsList(row){
        this.dialogVisibleEditGoods = true
        this.currentId = row.id
        this.getPresellActGoods(row.id)
      },

      // 获取活动关联的预售商品
      getPresellActGoods(act_id){
        presellApi.getPresellActGoods(act_id,this.presellActivityGoodsPage).then((res) => {
          this.presellGoodsData = res.data.data.data;
          this.presellActivityGoodsPage.total = res.data.data.total
        });
      },

      //选中操作
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      // 批量删除
      batchGoodsDel(){
        if (this.multipleSelection.length > 0) {
          let ids = { ids: [] };
          let goodsPush = [];
          for (let i = 0; i < this.multipleSelection.length; i++) {
            let theGoodsCode = this.multipleSelection[i].PresellActivitiesGoods.id;
            goodsPush.push(theGoodsCode);
          }
          ids.ids = goodsPush;
          presellApi.batchGoodsDel({
              data: ids,
            })
            .then((res) => {
              this.$message.success("删除成功");
              this.getPresellActGoods(this.currentId);
            });
        } else {
          this.$message.info("请选择商品");
        }
      },

      // 提交表单
      submit2presell(){
        let arr=[];
        this.saveGoodsData.skus.split("\n").forEach(item => {
            if (item) {
                arr.push(item.trim());
            }
        });
        var psotData = {
          act_id :this.saveGoodsData.act_id,
          skus:arr
        }
        //添加IP活动
				this.$axios
					.post(`/a1/presellActivites/addActGoods`, psotData)
					.then((res) => {
						if (res.error == 1) {
              this.$message.error(res.message)
            }else{
              this.$message.success("添加成功");
						  this.getList();
						  this.dialogVisibleGoods = false;
            }
					})
					.catch((resErr) => {
						this.$message.error("添加失败："+resErr.response.data.message);
					})
      },
      handleActivityGoodsSizeChange(val){
          this.presellActivityGoodsPage.pagesize = val;
        this.getPresellActGoods(this.currentId);
      },
      handleActivityGoodsCurrentChange(val){
          this.presellActivityGoodsPage.page = val
        this.getPresellActGoods(this.currentId);
      }
    },
  };
  </script>

  <style scoped>
  .el-date-editor.el-input{
      width: 100%;
  }
  .act_goods_input{
    padding: 0 10px;
  }
  .form_foot {
    width: 100%;
    background: white;
    border: 1px solid cadetblue;
    position: absolute;
    bottom: 1px;
    padding: 10px;

    &_submit {
      background: #218da0;
      border: 1px solid #218da0;
    }
  }
  </style>
